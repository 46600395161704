import type { GatsbySSR } from 'gatsby';
import { PageLayout } from './src/components/PageLayout';
import 'prism-themes/themes/prism-dracula.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

export const wrapPageElement: GatsbySSR<
  Record<string, unknown>,
  Queries.CreatePagesQuery['allMdx']['nodes'][number]
>['wrapPageElement'] = ({ element, props }) => (
  <PageLayout {...props}>{element}</PageLayout>
);
