import React, { FC, ReactNode, useMemo, useState } from 'react';
import { GlobalStyles } from 'twin.macro';
import { ThemeProvider } from './ThemeProvider';

import { StyleProvider } from '@ant-design/cssinjs';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { createGlobalStyle } from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

interface LayoutProps {
  children: ReactNode;
  pageContext: Queries.CreatePagesQuery['allMdx']['nodes'][number];
}

const CustomStyles = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    background: #fff;
  }

  .line-numbers .line-numbers-rows {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  
  .gatsby-highlight-code-line {
    background-color: #38394d;
    display: block;
    margin-right: -1em;
    margin-left: -1.25em;
    padding-right: 1em;
    padding-left: 0.75em;
    border-left: 0.5em solid #ff79c6;
  }

  :not(pre) > code.language-text {
    background-color: rgba(40, 41, 54, 0.2);
    color: hsla(0, 0%, 0%, 0.73);
  }

  :not(pre) > code.language-text::selection {
    color: white;
  }

  .gatsby-highlight,
  p.filename {
    margin-left: calc(-1 * var(--prism-padding));
    margin-right: calc(-1 * var(--prism-padding));
  }

  .gatsby-highlight + blockquote,
  .gatsby-highlight + p:not(.filename) {
    margin-top: 1.58em;
  }
`;

export const PageLayout: FC<LayoutProps> = ({ children, pageContext }) => {
  const [collapsed, setCollapsed] = useState(false);
  const title = pageContext?.frontmatter?.title;

  const data = useStaticQuery<GatsbyTypes.PageLayoutQuery>(graphql`
    query PageLayout {
      allSitePage(
        filter: { pluginCreator: { name: { eq: "gatsby-plugin-mdx" } } }
        sort: { path: ASC }
      ) {
        edges {
          node {
            path
            pageContext
          }
        }
        totalCount
      }
      metadata: site {
        site: siteMetadata {
          description
          title
          footerCopy
          siteUrl
          logo {
            childImageSharp {
              gatsbyImageData(height: 40, placeholder: NONE)
            }
            publicURL
          }
        }
      }
    }
  `);

  const getMenuItem = ({ label, key, icon, children, url }): MenuItem => {
    return {
      key,
      icon,
      label: <Link to={url}>{label}</Link>,
      ...(children?.length && {
        children: children.map(getMenuItem),
      }),
      url,
    } as MenuItem;
  };

  const menuItems: MenuItem[] = useMemo(() => {
    if (data.allSitePage.totalCount <= 1) {
      return [];
    }

    const final = { result: [] };
    const items = data.allSitePage.edges.filter(
      (item) => item.node.path !== '/'
    );

    for (const item of items) {
      let context = final;

      const { path } = item.node;
      const { node } = item.node.pageContext;
      const dirs = path.replace(/\/$/, '').replace(/^\//, '').split('/');

      dirs.forEach((dir) => {
        if (!context[dir]) {
          context[dir] = {
            result: [],
          };

          const children = context[dir].result;

          context.result.push({
            key: node.id,
            icon: node.frontmatter.menuIcon,
            dir,
            children,
            label: node.frontmatter.title ?? node.parent.name,
            url: path,
          });
        }

        context = context[dir];
      });
    }

    return final.result.map(getMenuItem);
  }, [data.allSitePage.edges, data.allSitePage.totalCount, getMenuItem]);

  const isCollapsible = menuItems.every((item) => !!item['icon']);

  return (
    <StyleProvider hashPriority="high">
      <GlobalStyles />
      <CustomStyles />

      <ThemeProvider>
        <Layout tw="!min-h-screen">
          <Sider
            collapsible={isCollapsible}
            collapsed={collapsed}
            onCollapse={setCollapsed}
          >
            {!!data.metadata.site.logo && (
              <Link to="/">
                <GatsbyImage
                  image={getImage(data.metadata.site.logo)}
                  alt={data.metadata.site.title}
                  tw="m-2"
                  // tw="w-full h-full object-cover rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                  // imgClassName="w-full h-full object-cover rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
                />
              </Link>
            )}
            <Menu defaultSelectedKeys={['1']} mode="inline" items={menuItems} />
          </Sider>
          <Layout>
            {title && <Header tw="mb-2 mx-2 bg-white">{title}</Header>}
            <Content tw="my-0 mx-2 bg-white">
              <section tw="p-6 min-h-max max-w-screen-2xl mx-auto">
                {children}
              </section>
            </Content>
            {data.metadata.site.footerCopy && (
              <Footer tw="text-center">{data.metadata.site.footerCopy}</Footer>
            )}
          </Layout>
        </Layout>
      </ThemeProvider>
    </StyleProvider>
  );
};
