import { ConfigProvider, theme } from 'antd';
import { FC, ReactNode } from 'react';

import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

interface AntdConfigProviderProps {
  children: ReactNode;
}

export const ThemeProvider: FC<AntdConfigProviderProps> = ({ children }) => {
  const { colors } = resolveConfig(tailwindConfig).theme;
  const { token } = theme.useToken();

  // https://ant.design/docs/react/customize-theme#life-of-design-token
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        components: {
          Layout: {
            headerBg: token.colorBgContainer,
            headerHeight: 40,
            headerPadding: `0 24px`,
            siderBg: token.colorBgContainer,
            triggerBg: token.colorBgContainer,
            triggerColor: token.colorPrimary,
          },
        },
        token: {
          colorPrimary: colors.primary[500],
          colorPrimaryBg: colors.white,
          //   colorLink: colors.blue[500],
          //   colorLinkHover: colors.blue[200],
          //   colorLinkActive: colors.blue[400],
          //   colorInfo: colors.blue[400],
          //   colorSuccess: colors.green[400],
          //   colorError: colors.red[400],
          //   colorWarning: colors.orange[400],
          //   colorTextBase: colors.black,
          //   fontSize: 14,
          //   // fontFamily: fontFamily.sans.join(', '),
          //   lineHeight: 1.5,

          // colorBgContainer: colors.white,
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
