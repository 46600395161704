import { type Config } from 'tailwindcss';

const config: Config = {
  content: ['./app/**/*.{tsx,md,mdx}'],
  corePlugins: {
    preflight: false,
  },
  theme: {
    extend: {
      colors: {
        primary: {
          50: '#f2f6fc',
          100: '#e2ebf7',
          200: '#cbdcf2',
          300: '#a7c5e9',
          400: '#7ea7dc',
          500: '#5f8ad2',
          600: '#4b70c5',
          700: '#415eb4',
          800: '#3a4e93',
          900: '#334375',
          950: '#232b48',
        },
        colorBgContainer: '#ffffff',
      },
    },
  },
};

export default config;
